import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Card, Col, Divider, Input, List, Row, Spin, Tag } from "antd";
import { cpf } from "cpf-cnpj-validator";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";
import { STATUS_PROGRAM } from "../../Enums/Program.enum";
import BCMap from "../../components/BCMaps";
import { list, validateCar } from "./service";

const { Search } = Input;

const objectBase = {
  _id: "",
  car: "",
  city: "",
  state: "",
  name: "",
  area: "",
  owners: [
    {
      name: "",
      cpfCnpj: "",
    },
  ],
  program: [
    {
      status: "",
      type: "",
    },
  ],
};

export default function AssessmentsEditViewCreate() {
  const { t } = useTranslation();
  const { id } = useParams();
  const theme = useTheme();
  const [farm, setFarm] = useState(objectBase);
  const [isLoading, setIsLoading] = useState(false);
  const [poligon, setPoligon] = useState(null);
  const [poligonCenter, setPoligonCenter] = useState(null);

  const findFarm = useCallback(async () => {
    const result = await list({
      id: id,
      withMaps: true,
    });
    if (result.result[0].maps.length === 0) {
      objectBase._id = result.result[0]._id;
      objectBase.car = result.result[0].car;
      setFarm(objectBase);
    } else {
      const property = result.result[0].maps[0].search.features.find(x => x.properties.codigo === result.result[0].car)
      const x =
      property.geometry.geometries[0]
          .coordinates;
      const y =
      property.geometry.geometries[1]
          .coordinates;

      setPoligon(x[0][0]);
      setPoligonCenter(y.reverse());
      setFarm(result.result[0]);
    }
  }, [id]);

  useEffect(() => {
    if (id) findFarm();
  }, [findFarm, id]);

  const handleInputChange = async (value) => {
    if (value.length > 10) {
      setIsLoading(true);
      try {
        const result = await validateCar({ car: value, cpf: null });
        const x =
          result.maps[0].search.features[0].geometry.geometries[0].coordinates;
        const y =
          result.maps[0].search.features[0].geometry.geometries[1].coordinates;

        setPoligon(x[0][0]);
        setPoligonCenter(y.reverse());
        setFarm(result);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleInputChangeCPF = async (value) => {
    if (!cpf.isValid(value)) {
      toast.error("Cpf Invalido");
    } else {
      if (value.length > 10) {
        setIsLoading(true);
        try {
          const result = await validateCar({
            car: null,
            cpf: cpf.format(value),
          });
          const x =
            result.maps[0].search.features[0].geometry.geometries[0]
              .coordinates;
          const y =
            result.maps[0].search.features[0].geometry.geometries[1]
              .coordinates;

          setPoligon(x[0][0]);
          setPoligonCenter(y.reverse());
          setFarm(result);
        } catch (error) {
          console.error("Error:", error);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case STATUS_PROGRAM.APPROVED:
        return (
          <Tag icon={<CheckCircleOutlined />} color={theme.tag_success}>
            {t("APPROVED")}
          </Tag>
        );
      case STATUS_PROGRAM.REVIEW:
        return (
          <Tag
            icon={<ExclamationCircleOutlined />}
            color={theme.tag_processing}
          >
            {t("REVIEW")}
          </Tag>
        );
      case STATUS_PROGRAM.REJECTED:
        return (
          <Tag icon={<CloseCircleOutlined />} color={theme.tag_error}>
            {t("REJECTED")}
          </Tag>
        );
      default:
        return null;
    }
  };

  return (
    <Spin
      spinning={isLoading}
      style={{ color: "green" }}
      size="large"
      tip={t("Loading_Message")}
    >
      <Row gutter={32}>
        <Col span={8}>
          <Row>
            <span style={{ color: "green" }}>
              {t("Property_Location_Required")}
            </span>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <span>{t("TEXT_NEW_ASSESSMENTS")}</span>
          </Row>
          <Row style={{ marginTop: "30px" }}>
            <Search
              disabled={id}
              placeholder={t("Placeholder_CAR")}
              onSearch={handleInputChange}
            />
          </Row>
          <Row style={{ marginTop: "30px" }}>
            <Search
              disabled={id}
              placeholder={t("Placeholder_CPF")}
              onSearch={handleInputChangeCPF}
            />
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col span={24}>
              {farm._id && (
                <Card title={t("Farm")}>
                  <p>
                    {t("Farm")}: {farm.name}
                  </p>
                  <p>
                    {t("Address")}: {farm.city} - {farm.state}
                  </p>
                  <p>
                    {t("Area")}: {farm.area} ha
                  </p>
                  <p>
                    {t("CAR")}: {farm.car}
                  </p>
                </Card>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col span={24}>
              {farm._id && (
                <Card title={t("Owner")}>
                  <List
                    dataSource={farm.owners}
                    renderItem={(owner) => (
                      <List.Item>
                        <List.Item.Meta
                          title={`${t("Name")}: ${owner.name}`}
                          description={`CPF/CNPJ: ${owner.cpfCnpj}`}
                        />
                      </List.Item>
                    )}
                  />
                </Card>
              )}
            </Col>
          </Row>
        </Col>
        <Col style={{ color: theme.titles }} span={16}>
          {farm._id && farm.program.length > 1 ? (
            <>
              <Row align="middle">
                <h2>
                  <UserOutlined style={{ marginRight: "8px" }} />
                  {t("TEXT_NEW_ASSESSMENTS2")}
                </h2>
                <Divider />
              </Row>
              <Row>
                <Col span={12}>
                  <Row gutter={[16, 16]} style={{ marginBottom: "8px" }}>
                    <Col span={14}>{t("TEXT_1")}</Col>
                    <Col span={10}>{getStatus(farm.program[0].status)}</Col>
                  </Row>
                  <Row gutter={[16, 16]} style={{ marginBottom: "8px" }}>
                    <Col span={14}>{t("TEXT_2")}</Col>
                    <Col span={10}>{getStatus(farm.program[1].status)}</Col>
                  </Row>
                  <Row gutter={[16, 16]} style={{ marginBottom: "8px" }}>
                    <Col span={14}>{t("TEXT_3")}</Col>
                    <Col span={10}>{getStatus(farm.program[2].status)}</Col>
                  </Row>
                  <Row gutter={[16, 16]} style={{ marginBottom: "8px" }}>
                    <Col span={14}>{t("TEXT_4")}</Col>
                    <Col span={10}>{getStatus(farm.program[4].status)}</Col>
                  </Row>
                  <Row gutter={[16, 16]} style={{ marginBottom: "8px" }}>
                    <Col span={14}>{t("TEXT_5")}</Col>
                    <Col span={10}>{getStatus(farm.program[6].status)}</Col>
                  </Row>
                  <Row gutter={[16, 16]} style={{ marginBottom: "8px" }}>
                    <Col span={14}>{t("TEXT_6")}</Col>
                    <Col span={10}>{getStatus(farm.program[5].status)}</Col>
                  </Row>
                  <Row gutter={[16, 16]} style={{ marginBottom: "8px" }}>
                    <Col span={14}>{t("TEXT_7")}</Col>
                    <Col span={10}>{getStatus(farm.program[3].status)}</Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Card>
                    {poligon && (
                      <BCMap
                        city={farm.city}
                        area={farm.area}
                        center={{
                          lat: poligonCenter[0],
                          lng: poligonCenter[1],
                        }}
                        paths={poligon.map((coord) => ({
                          lat: coord[1],
                          lng: coord[0],
                        }))}
                      />
                    )}
                  </Card>
                </Col>
              </Row>
            </>
          ) : null}
        </Col>
      </Row>
    </Spin>
  );
}
