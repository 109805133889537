import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { Formik } from "formik";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import * as Yup from "yup";
import { AuthContext } from "../../contexts/auth";
import BaseEntitlement from "../BaseEntitlement";

function Login() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { signIn, loadingAuth } = useContext(AuthContext);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("Invalid_Email"))
      .required(t("Email_is_required")),
    password: Yup.string()
      .min(8, t("Password_Least8"))
      .matches(/[A-Z]/, t("Password_Should_Contain_At_Least_1_Uppercase"))
      .matches(/[a-z]/, t("Password_Should_Contain_At_Least_1_Lowercase"))
      .matches(/[0-9]/, t("Password_Should_Contain_At_Least_1_Number"))
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        t("Password_Should_Contain_At_Least_1_Special_Character")
      )
      .required(t("Password_is_required")),
  });

  async function login(values) {
    await signIn(values.email, values.password);
  }

  return (
    <BaseEntitlement title={t("Login")}>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={login}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
        }) => (
          <Form layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              help={touched.email && errors.email ? errors.email : ""}
              validateStatus={touched.email && errors.email ? "error" : ""}
              data-cy="bc-email-error"
            >
              <div style={{ marginTop: "60px" }}>
                <Input
                  prefix={<MailOutlined />}
                  name="email"
                  value={values.email}
                  className="input"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t("Email")}
                  data-cy="bc-email"
                />
              </div>
            </Form.Item>
            <Form.Item
              help={touched.password && errors.password ? errors.password : ""}
              validateStatus={
                touched.password && errors.password ? "error" : ""
              }
              data-cy="bc-password-error"
            >
              <Input.Password
                prefix={<LockOutlined />}
                name="password"
                className="input"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t("Password")}
                data-cy="bc-password"
              />
            </Form.Item>
            <Form.Item>
              <Button
                style={{
                  backgroundColor: theme.button_save,
                  color: theme.button_save_font_color,
                }}
                data-cy="bc-submit"
                htmlType="submit"
                block
              >
                {!loadingAuth ? t("To_Enter") : t("Entering")}
              </Button>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </BaseEntitlement>
  );
}

export default Login;
