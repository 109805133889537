import "leaflet/dist/leaflet.css";
import "react-leaflet-fullscreen/styles.css";
import { LoadScript } from "@react-google-maps/api";
import { Tag } from "antd";
import React, { useMemo, useRef, useState } from "react";
import GeotiffLayer from "./BCTifLayer";
import ReactLoading from "react-loading";
import { MapContainer, TileLayer, Polygon, Tooltip } from "react-leaflet";
import { FullscreenControl } from "react-leaflet-fullscreen";

const containerStyle = {
  width: "100%",
  height: "400px",
  zIndex: 10,
};

function BCMap({ center, paths, area, city }) {
  const [vermelhoOverlay, setVermelhoOverlay] = useState(true);
  const [verdeOverlay, setVerdeOverlay] = useState(true);
  const [amareloOverlay, setAmareloOverlay] = useState(true);
  const [rosaOverlay, setRosaOverlay] = useState(true);
  const mapRef = useRef();


  function handleVermelhoOverlayChange(e) {
    setVermelhoOverlay(e.target.checked);
  }

  function handleAmareloOverlayChange(e) {
    setAmareloOverlay(e.target.checked);
  }

  function handleVerdeOverlayChange(e) {
    setVerdeOverlay(e.target.checked);
  }

  function handleRosaOverlayChange(e) {
    setRosaOverlay(e.target.checked);
  }

  function getUrlLayer() {
    let base_url = `https://prod-api.sabia.geopixel.earth/heatmap_filtering/{z}/{x}/{y}?baseprod=${city}`;

    const parametros = [];

    if (vermelhoOverlay) {
      parametros.push("base_class=1");
    }
    if (amareloOverlay) {
      parametros.push("base_class=2");
    }
    if (verdeOverlay) {
      parametros.push("base_class=3");
    }
    if (rosaOverlay) {
      parametros.push("base_class=4");
    }

    const link_final =
      parametros.length > 0 ? `${base_url}&${parametros.join("&")}` : base_url;

    return link_final;
  }

  console.log(getUrlLayer());
  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_YOUR_GOOGLE_MAPS_API_KEY}
    >
      {/* <div>
        <label>
          <input
            type="checkbox"
            checked={vermelhoOverlay}
            onChange={handleVermelhoOverlayChange}
          />
          &nbsp; Desmatamento Identificado nos últimos 10 anos (PRODES)
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            checked={amareloOverlay}
            onChange={handleAmareloOverlayChange}
          />
          &nbsp; Sem desmatamento Identificado nos últimos 10 anos (PRODES) e
          com Mata Secundária (Mantle Labs)
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            checked={rosaOverlay}
            onChange={handleRosaOverlayChange}
          />
          &nbsp; Floresta desde 2000 (PRODES 2022)
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            checked={verdeOverlay}
            onChange={handleVerdeOverlayChange}
          />
          &nbsp; Sem desmatamento Identificado nos últimos 10 anos (PRODES +
          Mantle Labs)
        </label>
      </div> */}
      <div
        style={{
          width: '100%',
          maxHeight: 400,
          minHeight: 400,
        }}
      >
        <MapContainer
          ref={mapRef}
          center={center}
          zoom={15}
          style={containerStyle}
          zoomControl={true}
        >
          <TileLayer
            url="https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
            attribution='&copy; <a href="https://maps.google.com">Google Maps</a> contributors'
            subdomains={["mt0", "mt1", "mt2", "mt3"]}
          />
          <TileLayer
            url={getUrlLayer()}
            attribution='&copy; <a href="https://maps.google.com">Google Maps</a> contributors'
            subdomains={["mt0", "mt1", "mt2", "mt3"]}
            opacity={0.4}
          />

          <FullscreenControl forceSeparateButton={true} position="topright" />

          {/* {loadingTif && (
            <div
              style={LoadStyle}
            >
              <ReactLoading
                type={"spin"}
                color={"#333"}
                height={30}
                width={30}
              />
            </div>
          )}
          <GeotiffLayer options={options} onLoading={setLoadingTif} /> */}

          <Polygon
            positions={paths}
            color="yellow"
            fillColor="tranparent"
            fillOpacity={0}
          >
            <Tooltip sticky>
              <div>Área: {area} ha</div>
            </Tooltip>
          </Polygon>
        </MapContainer>
      </div>

      <div>
        <label>
          <br />
          <div style={{ display: "flex" }}>
            <div style={{ height: 20, width: 13 }}>
              <input
                type="checkbox"
                checked={verdeOverlay}
                onChange={handleVerdeOverlayChange}
              />
            </div>
            <Tag
              style={{
                padding: "10px 10px",
                opacity: 1,
                height: 20,
                width: 13,
                marginLeft: 10,
              }}
              color="#58b1a0"
            />
            Verde: Elegível <br />
            Áreas que foram desflorestadas há mais de 10 anos, conforme dados do
            Prodes e Mantle Labs. São áreas que podem ser consideradas aptas
            para o programa.
          </div>
          <br />
          <div style={{ display: "flex" }}>
            <div style={{ height: 20, width: 13 }}>
              <input
                type="checkbox"
                checked={amareloOverlay}
                onChange={handleAmareloOverlayChange}
              />
            </div>
            <Tag
              style={{
                padding: "10px 10px",
                opacity: 1,
                height: 20,
                width: 13,
                marginLeft: 10,
              }}
              color="#ead835"
            />
            Amarelo: Elegível com atenção <br />
            Áreas que foram desflorestadas há mais de 10 anos, de acordo com o
            Prodes, e que, em algum momento nos últimos 10 anos, tiveram o
            crescimento de floresta secundária detectado pela Mantle Labs. São
            áreas que merecem atenção especial, mas ainda podem ser elegíveis.
          </div>
          <br />
          <div style={{ display: "flex" }}>
            <div style={{ height: 20, width: 13 }}>
              <input
                type="checkbox"
                checked={vermelhoOverlay}
                onChange={handleVermelhoOverlayChange}
              />
            </div>
            <Tag
              style={{
                padding: "10px 10px",
                opacity: 1,
                height: 20,
                width: 13,
                marginLeft: 10,
              }}
              color="#ef080b"
            />
            Vermelho: Inelegível <br />
            Áreas que foram desflorestadas há menos de 10 anos, de acordo com os
            registros do Prodes. Essas áreas não são consideradas aptas para ao
            programa.
          </div>
          <br />
          <div style={{ display: "flex" }}>
            <div style={{ height: 20, width: 13 }}>
              <input
                type="checkbox"
                checked={rosaOverlay}
                onChange={handleRosaOverlayChange}
              />
            </div>
            <Tag
              style={{
                padding: "10px 10px",
                opacity: 1,
                height: 20,
                width: 13,
                marginLeft: 10,
              }}
              color="#de9fbc"
            />
            Rosa: Inelegível <br />
            Áreas florestais que estão preservadas há mais de 22 anos, de acordo
            com os dados do Prodes. Tais áreas também são consideradas
            inelegíveis ao programa.
          </div>
          <br />

          <div
            style={{
              color: "#1E90FF",
              fontSize: "13px",
              marginTop: "10px",
              fontStyle: "italic",
            }}
          >
            Os dados pesquisados para a avaliação de 10 anos compreendem o
            período de 2013 a 2022
          </div>
        </label>
      </div>
    </LoadScript>
  );
}

export default BCMap;

const LoadStyle = {
  position: "absolute",
  width: "100%",
  minHeight: 400,
  zIndex: 1000,
  backgroundColor: "#fff7",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};