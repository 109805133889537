import { LockOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { Formik } from "formik";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import * as Yup from "yup";
import { AuthContext } from "../../contexts/auth";
import { changePasswordService } from "./service";

export default function UserChangePassword() {
  const theme = useTheme();
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    if (id !== user._id) {
      logout();
    }
  }, [id, user, logout]);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, t("Password_Least8"))
      .matches(/[A-Z]/, t("Password_Should_Contain_At_Least_1_Uppercase"))
      .matches(/[a-z]/, t("Password_Should_Contain_At_Least_1_Lowercase"))
      .matches(/[0-9]/, t("Password_Should_Contain_At_Least_1_Number"))
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        t("Password_Should_Contain_At_Least_1_Special_Character")
      )
      .required(t("Password_is_required")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("Passwords_must_match"))
      .required(t("Confirm_Password_is_required")),
  });

  async function changePasswordPerfil(values) {
    try {
      await changePasswordService(values.password, values.confirmPassword);
      navigate("/assessments");
    } catch (error) {
      console.log("AQUI", error);
    }
  }

  return (
    <div>
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        validationSchema={validationSchema}
        onSubmit={changePasswordPerfil}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
        }) => (
          <Form layout="vertical" onSubmitCapture={handleSubmit}>
            <Form.Item
              help={touched.password && errors.password ? errors.password : ""}
              validateStatus={
                touched.password && errors.password ? "error" : ""
              }
            >
              <Input.Password
                prefix={<LockOutlined />}
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                className="input"
                placeholder={t("Password")}
                style={{ width: "25%" }}
              />
            </Form.Item>
            <Form.Item
              help={
                touched.confirmPassword && errors.confirmPassword
                  ? errors.confirmPassword
                  : ""
              }
              validateStatus={
                touched.confirmPassword && errors.confirmPassword ? "error" : ""
              }
            >
              <Input.Password
                prefix={<LockOutlined />}
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                className="input"
                placeholder={t("Confirm_Password")}
                style={{ width: "25%" }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                style={{
                  backgroundColor: theme.button_save,
                  color: theme.button_save_font_color,
                  width: "100%",
                  maxWidth: "200px",
                }}
                htmlType="submit"
                block
              >
                {t("Change")}
              </Button>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
}
