import { ConfigProvider } from "antd";
import ptBR from "antd/lib/locale/pt_BR";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthProvider from "./contexts/auth.js";
import { LanguageProvider } from "./contexts/languageContext.js";
import "./i18n";
import RoutesApp from "./routes/routes.js";

function App() {
  return (
    <BrowserRouter>
      <ConfigProvider locale={ptBR}>
        <LanguageProvider>
          <AuthProvider>
            <ToastContainer autoClose={3000} />
            <RoutesApp />
          </AuthProvider>
        </LanguageProvider>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
