import axios from "../../apis/axios";

export const list = async ({
  limit = 10,
  page = 1,
  id = "",
  sort = 1,
  field = "name",
  withMaps = "false",
  search = "",
  car = "",
  city = "",
  farmerName = "",
  greaterThanArea = "",
  greaterThanDate = "",
  lessThanArea = "",
  lessThanDate = "",
  propertyName = "",
  status = "",
}) => {
  const result = await axios.get(
    `/v1/farms?limit=${limit}&page=${page}&id=${id}&sort=${sort}&field=${field}&withMaps=${withMaps}&search=${search}&car=${car}&city=${city}&farmerName=${farmerName}&propertyName=${propertyName}&status=${status}&greaterThanArea=${greaterThanArea}&greaterThanDate=${greaterThanDate}&lessThanArea=${lessThanArea}&lessThanDate=${lessThanDate}`
  );
  return result.data.data;
};

export const uploadFileApi = async (formData) => {
  const result = await axios.post(`/v1/farms/validate-car`, formData);
  return result.data.data;
};

export const getReport = async () => {
  return await axios.get(`/v1/farms/report`, { responseType: "blob" });
};

export const validateCar = async ({ car = null, cpf = null }) => {
  const result = await axios.get(
    `v1/farms/validate-car-cpf?car=${car}&cpf=${cpf}`
  );
  return result.data.data;
};
