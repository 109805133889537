import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import BaseLayout from "../../pages/BaseLayout";
import UserChangePassword from "../../pages/User/UserChangePassword";
import Private from "../Private";

function UserRoutes() {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route
        path="/:id/perfil/change_password"
        element={
          <Private>
            <BaseLayout breadcrumb={t("User_Profile_Password_Change")}>
              <UserChangePassword />
            </BaseLayout>
          </Private>
        }
      />
    </Routes>
  );
}

export default UserRoutes;
