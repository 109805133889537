import { Menu } from "antd";
import styled from "styled-components";
import theme from "../../theme";

const CustomMenu = styled(Menu)`
  .ant-menu-item-selected {
    background-color: ${theme.sider_top} !important;
  }

  .ant-menu-item-selected > span {
    color: white !important;
  }
`;

export default CustomMenu;
