import { Card, Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useTheme } from "styled-components";
import "./BaseEntitlement.css";

function BaseEntitlement({ children, title, subtitle = null, to = null }) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 1340 });
  const theme = useTheme();

  return (
    <div className="login-container">
      {!isMobile && (
        <div className="centered-image-text">
          <h1 style={{ color: theme.titles }} className="centered-text">
            {t("PRE_VALIDATION_SYSTEM")}
          </h1>
        </div>
      )}
      <Row style={{ minHeight: "100vh" }} align="middle" justify="end">
        <Col xs={22} sm={16} md={12} lg={8} xl={6}>
          <Card
            style={{
              width: "100%",
              height: "350px",
              maxWidth: "400px",
              position: "relative",
              transform: "translateX(-80%)",
            }}
          >
            <Row justify="center" align="middle" className="card-header">
              <div style={{ textAlign: "center" }}>
                <h1 style={{ color: theme.titles }}>{title}</h1>
              </div>
            </Row>
            {children}
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default BaseEntitlement;
