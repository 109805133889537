export const STATUS_PROGRAM = Object.freeze({
  APPROVED: "APPROVED",
  NEW: "NEW",
  PENDING: "PENDING",
  REFUSED: "REFUSED",
  REJECTED: "REJECTED",
  REISSUE: "REISSUE",
  REVIEW: "REVIEW",
  SIGNED: "SIGNED",
});

export const CRITERION = Object.freeze({
  FISCAL_MODULE: "FISCAL_MODULE",
  ITS_IN_THE_PARA: "ITS_IN_THE_PARA",
  ITS_IN_THE_CITY_WE_SERVE: "ITS_IN_THE_CITY_WE_SERVE",
  HAS_INDIGENOUS_AREA: "HAS_INDIGENOUS_AREA",
  HAS_A_PRESERVATION_AREA: "HAS_A_PRESERVATION_AREA",
  HAS_DEFORESTATION: "HAS_DEFORESTATION",
  HAS_SLAVERY: "HAS_SLAVERY",
});
