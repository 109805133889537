const theme = {
  titles: "#5C4C45",
  sider: "#7A6B65",
  sider_top: "#47342E",
  header: "white",
  tag_success: "green",
  tag_processing: "blue",
  tag_error: "red",
  button_save: "rgb(79, 175, 73)",
  button_save_font_color: "white",
};

export default theme;
