import axios from "axios";
import jwt from "jwt-decode";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
export const AuthContext = createContext({});

// Apenas para a Parte de Entitlement que vamos instanciar o Axios Diretamente
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    async function loadUser() {
      const storageUser = localStorage.getItem("userLogged");
      if (storageUser) {
        setUser(JSON.parse(storageUser));
        setLoading(false);
      }
      setLoading(false);
    }
    loadUser();
  }, []);

  async function signIn(email, password) {
    setLoadingAuth(true);

    try {
      const encoder = new TextEncoder();
      const base64String = btoa(
        String.fromCharCode(
          ...new Uint8Array(encoder.encode(`${email}:${password}`))
        )
      );

      const config = {
        headers: {
          Authorization: `Basic ${base64String}`,
        },
      };

      const response = await apiClient.post("v1/user/login", {}, config);
      const { data } = response.data;
      const user = jwt(data);
      setUser(user);
      storageUser(user, data);
      setLoadingAuth(false);
      toast.success(response.data.message);
      navigate("/assessments");
    } catch (error) {
      if (error.response && error.response.status < 500) {
        toast.warn(error.response.data.message);
      } else {
        toast.error("Ops, algo deu errado");
      }
      setLoadingAuth(false);
    }
  }

  function updateUser(name, email, phone, whatsapp, userId) {
    setUser(null);
    setUser({ name, email, phone, whatsapp, user_id: userId });
  }

  function storageUser(data, token) {
    localStorage.setItem("userLogged", JSON.stringify(data));
    localStorage.setItem("token", token);
  }

  // Aqui estamos limpando todos os dados do usuário
  async function logout() {
    localStorage.removeItem("userLogged");
    localStorage.removeItem("token");
    setUser(null);
    navigate("/login");
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        signIn,
        logout,
        loadingAuth,
        loading,
        storageUser,
        setUser,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
