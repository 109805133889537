import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../Entitlement/Login/Login";
import Assessments from "../pages/Assessments/Assessments";
import AssessmentsEditViewCreate from "../pages/Assessments/AssessmentsEditViewCreate";
import BaseLayout from "../pages/BaseLayout";
import Private from "./Private";
import UserRoutes from "./Users/Users.routes";

function RoutesApp() {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />

      <Route
        path="/assessments"
        element={
          <Private>
            <BaseLayout>
              <Assessments />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/assessments/create"
        element={
          <Private>
            <BaseLayout gotoback={"/assessments"}>
              <AssessmentsEditViewCreate />
            </BaseLayout>
          </Private>
        }
      />

      <Route
        path="/assessments/:id/view"
        element={
          <Private>
            <BaseLayout gotoback={"/assessments"}>
              <AssessmentsEditViewCreate />
            </BaseLayout>
          </Private>
        }
      />

      <Route path="user/*" element={<UserRoutes />} />

      {/* Dim das Áreas de Notificatções */}

      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
}

export default RoutesApp;
