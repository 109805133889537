import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Input, Row, Select } from "antd";
import dayjs from "dayjs";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import "dayjs/locale/pt-br";
import { FARM } from "../Enums/Farm.enum";

const { Option } = Select;
const { Panel } = Collapse;

dayjs.locale("pt-br");

const BCAdvancedFilter = ({ onFilter }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const initialValues = {
    status: "",
    greaterThanDate: "",
    lessThanDate: "",
    greaterThanArea: "",
    lessThanArea: "",
    propertyName: "",
    farmerName: "",
    car: "",
    city: "",
  };

  const handleSubmit = (values) => {
    values.greaterThanDate =
      values.greaterThanDate && values.greaterThanDate.isValid()
        ? values.greaterThanDate.format("YYYY-MM-DD HH:mm:ss")
        : "";

    values.lessThanDate =
      values.lessThanDate && values.lessThanDate.isValid()
        ? values.lessThanDate.format("YYYY-MM-DD HH:mm:ss")
        : "";

    console.log("AAAAAAAAA", values.greaterThanDate);

    onFilter(values);
  };
  const status = [
    {
      name: t("APPROVED"),
      value: FARM.APPROVED,
    },
    {
      name: t("REJECTED"),
      value: FARM.REJECTED,
    },
    {
      name: t("REVIEW"),
      value: FARM.REVIEW,
    },
    {
      name: t("All"),
      value: "",
    },
  ];

  return (
    <Collapse style={{ marginBottom: "20px" }}>
      <Panel header="Filtro Avançado" key="1">
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ resetForm }) => (
            <Form>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Field name="status">
                    {({ field }) => (
                      <Select
                        {...field}
                        placeholder="Selecione um status"
                        style={{ width: "100%" }}
                        value={field.value || undefined}
                        onChange={(value) => field.onChange(field.name)(value)}
                      >
                        <Option value="" disabled>
                          {t("Selecione um status")}
                        </Option>
                        {status.map((option) => (
                          <Option key={option.value} value={option.value}>
                            {option.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Field>
                </Col>

                {/* <Col xs={24} sm={12} md={8} lg={6}>
                <Field name="greaterThanDate" as={DatePicker}>
                  {({ field, form }) => (
                    <DatePicker
                      {...field}
                      format="DD/MM/YYYY"
                      placeholder="Avaliada em, maior que"
                      style={{ width: "100%" }}
                      onChange={(date) => form.setFieldValue(field.name, date)}
                    />
                  )}
                </Field>
              </Col>

              <Col xs={24} sm={12} md={8} lg={6}>
                <Field name="lessThanDate" as={DatePicker}>
                  {({ field, form }) => (
                    <DatePicker
                      {...field}
                      format="DD/MM/YYYY"
                      placeholder="Avaliado em, menor que"
                      style={{ width: "100%" }}
                      onChange={(date) => form.setFieldValue(field.name, date)}
                    />
                  )}
                </Field>
              </Col> */}

                <Col xs={24} sm={12} md={8} lg={6}>
                  <Field
                    name="greaterThanArea"
                    as={Input}
                    type="number"
                    placeholder="Área maior que"
                    style={{ width: "100%" }}
                  />
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                  <Field
                    name="lessThanArea"
                    as={Input}
                    type="number"
                    placeholder="Área menor que"
                    style={{ width: "100%" }}
                  />
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                  <Field
                    name="propertyName"
                    as={Input}
                    placeholder="Nome da propriedade"
                    style={{ width: "100%" }}
                  />
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                  <Field
                    name="farmerName"
                    as={Input}
                    placeholder="Nome do proprietario"
                    style={{ width: "100%" }}
                  />
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                  <Field
                    name="city"
                    as={Input}
                    placeholder="Cidade"
                    style={{ width: "100%" }}
                  />
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                  <Field
                    name="car"
                    as={Input}
                    placeholder="CAR"
                    style={{ width: "100%" }}
                  />
                </Col>

                <Col xs={24}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<FilterOutlined />}
                    style={{
                      backgroundColor: theme.button_save,
                      color: theme.button_save_font_color,
                    }}
                  >
                    Filtrar
                  </Button>
                  <Button
                    type="secondary"
                    htmlType="button"
                    icon={<DeleteOutlined />}
                    onClick={() => resetForm()}
                    style={{
                      marginLeft: "10px",
                      backgroundColor: theme.button_clear,
                      color: theme.button_clear_font_color,
                    }}
                  >
                    Limpar
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Panel>
    </Collapse>
  );
};

export default BCAdvancedFilter;
