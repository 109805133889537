import axios from "axios";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { toast } from "react-toastify";

const API_BASE_URL = process.env.REACT_APP_API_URL;
const HTTP_METHODS_WITH_BODY = ["put", "patch", "delete", "post"];
const HTTP_UNAUTHORIZED = 401;
const HTTP_FORBIDDEN = 403;
const HTTP_SERVER_ERROR = 500;

const apiClient = axios.create({
  baseURL: API_BASE_URL,
});

const logRequestData = (config) => {
  // Edu, estou colocando essa informação para ajudar no desenvolvimento
  // Toda requisição que sair da aplicação vai ir para o console, ajudando assim
  // na verificação dos payloads
  if (HTTP_METHODS_WITH_BODY.includes(config.method)) {
    console.log(
      `Dados da Requisição que está saindo na URL: ${
        config.url
      } ${JSON.stringify(config.data)}`
    );
  }
};

const handleErrorResponse = (error) => {
  if (error.response) {
    const status = error.response.status;
    const message = error.response.data.message;
    // Todo erro HTTP_FORBIDDEN ou HTTP_UNAUTHORIZED irá deslogar o usuário
    if (status === HTTP_FORBIDDEN || status === HTTP_UNAUTHORIZED) {
      toast.warn(message);
      localStorage.removeItem("userLogged");
      localStorage.removeItem("token");
      window.history.pushState(null, "", "/login");
    } else if (status < HTTP_SERVER_ERROR) {
      // Todo erro diferente de 500 será exibido no TOAST para o Usuário
      toast.warn(message);
    } else {
      toast.error("Ops, algo deu errado");
    }
  } else {
    toast.error("Ops, algo deu errado");
  }
  return Promise.reject(error);
};

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    logRequestData(config);
    NProgress.start();
    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => {
    if (HTTP_METHODS_WITH_BODY.includes(response.config.method)) {
      toast.success(response.data.message);
    }
    NProgress.done();
    return response;
  },
  (error) => {
    NProgress.done();
    return handleErrorResponse(error);
  }
);

export default apiClient;
