import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Col, List, Row, Space, Tag, Tooltip, Upload } from "antd";
import { saveAs } from "file-saver-es";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import { FARM } from "../../Enums/Farm.enum";
import { STATUS_PROGRAM } from "../../Enums/Program.enum";
import BCTable from "../../components/BCTable";
import generateExcelFile from "../../utils/generateExcel";
import { getReport, list, uploadFileApi } from "./service";

export default function Assessments() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [farms, setFarms] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const [sortField, setSortField] = useState("name");
  const [loading, setLoading] = useState(true);
  const [spinUpload, setSpinUpload] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);
  const [car, setCar] = useState("");
  const [city, setCity] = useState("");
  const [farmerName, setFarmerName] = useState("");
  const [greaterThanArea, setGreaterThanArea] = useState("");
  const [greaterThanDate, setGreaterThanDate] = useState("");
  const [lessThanArea, setLessThanArea] = useState("");
  const [lessThanDate, setLessThanDate] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [status, setStatus] = useState("");

  const { t } = useTranslation();

  const findFarms = useCallback(async () => {
    setLoading(true);
    const result = await list({
      limit: pageSize,
      page: currentPage,
      sort: sortOrder,
      field: sortField,
      search: search,
      car,
      city,
      farmerName,
      greaterThanArea,
      greaterThanDate,
      lessThanArea,
      lessThanDate,
      propertyName,
      status,
    });
    result.result.map((x) => (x.key = x._id));
    setFarms(result.result);
    setTotal(result.count);
    setLoading(false);
  }, [
    pageSize,
    currentPage,
    search,
    sortOrder,
    sortField,
    car,
    city,
    farmerName,
    greaterThanArea,
    greaterThanDate,
    lessThanArea,
    lessThanDate,
    propertyName,
    status,
  ]);

  useEffect(() => {
    findFarms();
  }, [findFarms]);

  const onView = (key) => {
    navigate(`/assessments/${key}/view`);
  };

  const downloadReportComplete = async () => {
    disabledButtons(true);
    const response = await getReport();
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "data.xlsx");
    disabledButtons(false);
  };

  const downloadReportScreen = async () => {
    disabledButtons(true);
    const report = [];
    for (let index = 0; index < farms.length; index++) {
      const farm = farms[index];

      const ownersString = farm.owners
        .map((element) => {
          return `Nome: ${element.name}, CPF/CNPJ: ${element.cpfCnpj}, Tipo: ${element.type}`;
        })
        .join("; ");

      report.push({
        CAR: farm.car,
        Propriedade: farm.name,
        Cidade: farm.city,
        Estado: farm.state,
        CEP: farm.cep,
        "Proprietarios(a)": ownersString,
        Status: getStatus(farm.status),
        "Atende ao Modulo Fiscal da cidade ?": getStatus(
          farm.program[0]?.status
        ),
        "Está contida no Pará ?": getStatus(farm.program[1]?.status),
        "É uma cidade Atendida ?": getStatus(farm.program[2]?.status),
        "Está em área indigena ?": getStatus(farm.program[3]?.status),
        "Está em área de preservação ?": getStatus(farm.program[4]?.status),
        "Tem desflorestamento ?": getStatus(farm.program[5]?.status),
        "Tem trabalho escravo ?": getStatus(farm.program[6]?.status),
      });
    }
    generateExcelFile(report);
    disabledButtons(false);
  };

  const getStatus = (status) => {
    switch (status) {
      case STATUS_PROGRAM.APPROVED:
        return t("APPROVED");
      case STATUS_PROGRAM.REVIEW:
        return t("REVIEW");
      case STATUS_PROGRAM.REJECTED:
        return t("REJECTED");
      default:
        return "";
    }
  };

  const uploadFile = async (file) => {
    disabledButtons(true);
    const formData = new FormData();
    formData.append("file", file);
    try {
      await uploadFileApi(formData);
      await findFarms();
      disabledButtons(false);
    } catch (error) {
      disabledButtons(false);
    }
  };

  const disabledButtons = (disabled) => {
    setSpinUpload(disabled);
    setLoading(disabled);
    setDisableButtons(disabled);
  };

  const clickNewAssessments = () => {
    navigate("/assessments/create");
  };

  const handleFilter = (values) => {
    setCar(values.car);
    setCity(values.city);
    setFarmerName(values.farmerName);
    setGreaterThanArea(values.greaterThanArea);
    setGreaterThanDate(values.greaterThanDate);
    setLessThanArea(values.lessThanArea);
    setLessThanDate(values.lessThanDate);
    setPropertyName(values.propertyName);
    setStatus(values.status);
  };

  const columns = [
    {
      title: t("Evaluated_in"),
      dataIndex: "created_in",
      align: "center",
      render: (text, record) => (
        <span key={record.created_in}>
          {new Date(record.created_in).toLocaleDateString()}
        </span>
      ),
    },
    {
      title: t("Owner"),
      dataIndex: "owners",
      align: "center",
      render: (text, record) => {
        if (record.owners.length === 0) {
          return null;
        }
        return (
          <List
            size="small"
            dataSource={record.owners}
            renderItem={(owner) => <List.Item>{owner.name.trim()}</List.Item>}
          />
        );
      },
    },
    {
      title: t("Area_Property"),
      dataIndex: "area",
      align: "center",
      render: (text, record) => <span key={record.area}>{record.area} ha</span>,
    },
    {
      title: t("Property"),
      dataIndex: "name",
      align: "center",
      sorter: true,
    },
    {
      title: t("City"),
      dataIndex: "City",
      align: "center",
      render: (text, record) => (
        <span key={record.city}>
          {record.city ? `${record.city} - ${record.state}` : null}
        </span>
      ),
    },
    {
      title: t("Status"),
      dataIndex: "status",
      align: "center",
      render: (text, record) => {
        switch (record.status) {
          case FARM.APPROVED:
            return (
              <Tag icon={<CheckCircleOutlined />} color={theme.tag_success}>
                {t("APPROVED")}
              </Tag>
            );
          case FARM.REVIEW:
            return (
              <Tag
                icon={<ExclamationCircleOutlined />}
                color={theme.tag_processing}
              >
                {t("REVIEW")}
              </Tag>
            );
          case FARM.REJECTED:
            return (
              <Tag icon={<CloseCircleOutlined />} color={theme.tag_error}>
                {t("REJECTED")}
              </Tag>
            );
          default:
            return null;
        }
      },
    },
  ];

  const actionsColumn = [
    {
      title: t("Actions"),
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Button
            shape="round"
            type="dashed"
            onClick={() => onView(record.key)}
            icon={<EyeOutlined />}
          >
            {t("View")}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ textAlign: "right" }}>
      <BCTable
        data={farms}
        total={total}
        pageSize={pageSize}
        currentPage={currentPage}
        placeholderSearch={`${t("Search")} ${t("Farms")}`}
        setSearch={setSearch}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
        loading={loading}
        columns={columns}
        actionsColumn={actionsColumn}
        showCreateButton={false}
        onFilter={handleFilter}
        children={
          <Row gutter={16}>
            <Col>
              <Tooltip placement="top" title={t("BASE_FILE_TOOLTIP")}>
                <a
                  href="\Planilha para avaliação de CARs.xlsx"
                  style={{ marginRight: "20px" }}
                >
                  {t("BASE_FILE")}
                </a>
              </Tooltip>

              <Tooltip placement="top" title={t("INFOS_UPLOAD")}>
                <Upload
                  accept=".xlsx"
                  showUploadList={false}
                  beforeUpload={(file) => {
                    uploadFile(file);
                    return false;
                  }}
                  multiple={false}
                >
                  <Button
                    disabled={disableButtons}
                    style={{
                      backgroundColor: theme.button_save,
                      color: theme.button_save_font_color,
                    }}
                    icon={<UploadOutlined spin={spinUpload} />}
                  >
                    {t("UPLOAD_SPREADSHEET")}
                  </Button>
                </Upload>
              </Tooltip>
            </Col>
            <Col>
              <Button
                onClick={() => clickNewAssessments()}
                disabled={disableButtons}
                style={{
                  backgroundColor: theme.button_save,
                  color: theme.button_save_font_color,
                }}
                icon={<PlusOutlined />}
              >
                {t("NEW_ASSESSMENTS")}
              </Button>
            </Col>
          </Row>
        }
      />
      <Row gutter={16} justify="end">
        <Col>
          <Button
            disabled={disableButtons}
            icon={<DownloadOutlined />}
            onClick={() => downloadReportScreen()}
          >
            {t("DOWNLOAD_REPORT")} {t("Screen")}
          </Button>
        </Col>
        <Col>
          <Button
            disabled={disableButtons}
            icon={<DownloadOutlined />}
            onClick={() => downloadReportComplete()}
          >
            {t("DOWNLOAD_REPORT")} {t("Complete")}
          </Button>
        </Col>
      </Row>
    </div>
  );
}
