import { useEffect, useRef } from "react";
import proj4 from "proj4";
import { useLeafletContext } from "@react-leaflet/core";
import { useMap } from "react-leaflet";
import parseGeoraster from "georaster";
import GeoRasterLayer from "georaster-layer-for-leaflet";

// import heatMap2022 from "../assets/heat_map_2022.tif";

window.proj4 = proj4;

const GeotiffLayer = ({ url, options, onLoading }) => {
  const geoTiffLayerRef = useRef();
  const context = useLeafletContext();
  const map = useMap();

  useEffect(() => {
    if(onLoading) onLoading(true);
    const container = context.layerContainer || context.map;
    // fetch(heatMap2022)
    //   .then((response) => response.arrayBuffer())
    //   .then((arrayBuffer) => {
    //     parseGeoraster(arrayBuffer).then((georaster) => {
    //       options.georaster = georaster;
    //       geoTiffLayerRef.current = new GeoRasterLayer(options);
    //       container.addLayer(geoTiffLayerRef.current);

    //     if(onLoading) onLoading(false);
    //     });
    //   });

    return () => {
      container.removeLayer(geoTiffLayerRef.current);
    };
  }, [context, map, options]);

  return null;
};

export default GeotiffLayer;
