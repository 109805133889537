import { saveAs } from "file-saver-es";
import { utils, write } from "xlsx";

const generateExcelFile = (jsonData) => {
  const workbook = utils.book_new();
  const worksheet = utils.json_to_sheet(jsonData);
  utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const excelBuffer = write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, "data.xlsx");
};

export default generateExcelFile;
