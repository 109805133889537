import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {},
  },
  pt: {
    translation: {
      Password_Should_Contain_At_Least_1_Number:
        "A senha deve conter no mínimo 1 caracter",
      Invalid_Email: "Email inválido",
      INFOS_UPLOAD:
        "Ao fazer o upload do arquivo, o mesmo será processado em background não impedindo a utilização do sistema, o tempo de processamento pode variar de acordo com a quantidade de dados, os dados serão atualizadas na listagem 1 a 1",
      Property: "Propriedade",
      BASE_FILE_TOOLTIP:
        "Esse arquivo contem apenas uma coluna car que deve ser preenchida com os CARs desejados",
      BASE_FILE: "Baixar arquivo base",
      All: "Todos",
      Advanced_filter: "Filtro Avançado",
      Address: "Endereço",
      Area: "Área",
      Name: "Nome",
      TEXT_1: "Menor que 4 modulos fiscais",
      TEXT_2: "Contida no Pará",
      TEXT_3: "Cidade atendida",
      TEXT_4: "Sem sobreposição com Unidades de Conservação",
      TEXT_5: "Sem trabalho análogo à escravidão",
      TEXT_6: "Sem desmatamento nos últimos 5 anos",
      TEXT_7: "Sem sobreposição com terras Indígenas",
      TEXT_NEW_ASSESSMENTS2: "Status dos Critérios de Elegibilidade",
      Loading_Message:
        "Por favor aguarde enquanto estamos coletando as informações",
      Placeholder_CAR: "Informe o Car",
      Placeholder_CPF: "Informe o CPF",
      TEXT_NEW_ASSESSMENTS:
        "Para iniciar um processo de avaliação de pré triagem, por favor, identifique a propriedade informando o CAR:",
      Property_Location_Required: "Localização da propriedade obrigatória",
      Password_Least8: "A senha deve conter no minimo 8 caracteres",
      Entering: "Acessando",
      User_Profile_Password_Change: "Usuário / Alteração de Senha",
      Confirm_Password: "Confirmação de Senha",
      Change: "Alterar",
      Confirm_Password_is_required:
        "É necessário informar a confirmação da senha",
      Passwords_must_match: "As senhas não conferem",
      Password_Should_Contain_At_Least_1_Special_Character:
        "A senha deve conter ao menos um caracter especial",
      Password_Should_Contain_At_Least_1_Lowercase:
        "A senha deve conter ao menos uma letra minuscula",
      Password_Should_Contain_At_Least_1_Uppercase:
        "A senha deve conter ao menos uma letra maiuscula",
      Complete: "Completo",
      Screen: "Tela",
      NEW_ASSESSMENTS: "Nova avaliação",
      UPLOAD_SPREADSHEET: "Upload de Planilha",
      DOWNLOAD_REPORT: "Baixar Relatório",
      APPROVED: "Aprovada",
      REVIEW: "Demanda análise",
      REJECTED: "Rejeitada",
      View: "Visualizar",
      City: "Cidade",
      Area_Property: "Área da Propriedade",
      Owner: "Proprietário(a)",
      Evaluated_in: "Avaliada em",
      FARMER_EVALUATION: "Avaliações das Propriedades",
      ASSESSMENTS: "Avaliações",
      PRE_VALIDATION_SYSTEM: "Sistema de Pré Validação",
      Search: "Pesquisar",
      Actions: "Ações",
      Gotoback: "Voltar",
      Login: "Acesso",
      Farms: "Propriedades",
      Farm: "Propriedade",
      Exit: "Sair",
      ChangePassword: "Trocar Senha",
      To_Enter: "Entrar",
      Email_is_required: "Email é obrigatório",
      Password_is_required: "Senha é obrigatória",
      Password: "Senha",
      Name_APP: "Acelerador de Agroflorestas e Restauração",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "pt",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
