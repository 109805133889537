import {
  ArrowLeftOutlined,
  LockOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { Avatar, Breadcrumb, Button, Card, Dropdown, Layout, Menu } from "antd";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdPlaylistAdd } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import logo from "../../assets/logo2.png";
import { AuthContext } from "../../contexts/auth";
import CustomMenu from "./BaseStyle";

const { Header, Content, Footer, Sider } = Layout;

function Base({ children, breadcrumb, gotoback }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { logout, user } = useContext(AuthContext);
  const [collapsed, setCollapsed] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 790 });
  const theme = useTheme();

  const getSelectedKey = () => {
    if (location.pathname.includes("/assessments")) return "1";
    if (location.pathname.includes("/user")) return "1";
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="2"
        icon={<LockOutlined />}
        onClick={() => navigate(`/user/${user._id}/perfil/change_password`)}
      >
        {t("ChangePassword")}
      </Menu.Item>
      <Menu.Item key="3" icon={<LogoutOutlined />} onClick={() => logout()}>
        {t("Exit")}
      </Menu.Item>
    </Menu>
  );

  const avatarMenu = (
    <Dropdown overlay={menu} placement="bottomRight">
      <Avatar icon={<UserOutlined />} />
    </Dropdown>
  );

  function getItem(label, key, icon, onClick, children) {
    return {
      key,
      icon,
      children,
      onClick,
      label,
    };
  }

  const items = [
    getItem(
      t("FARMER_EVALUATION"),
      "1",
      <MdPlaylistAdd style={{ color: "white", fontSize: "20px" }} />,
      () => navigate("/assessments")
    ),
  ];

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        style={{ padding: 0, backgroundColor: theme.sider }}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        trigger={null}
        collapsedWidth={60}
        width={280}
      >
        <div
          style={{
            height: 65,
            marginBottom: 15,
            background: theme.sider_top,
            position: "relative",
          }}
        >
          {!collapsed ? (
            <div
              onClick={() => setCollapsed(!collapsed)}
              style={{
                cursor: "pointer",
                height: "100%",
                width: "100%",
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
              }}
            >
              {t("PRE_VALIDATION_SYSTEM")}
            </div>
          ) : (
            <img
              onClick={() => setCollapsed(!collapsed)}
              src={logo}
              alt="Descrição da Imagem"
              style={{
                cursor: "pointer",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          )}
        </div>

        <CustomMenu
          selectedKeys={[getSelectedKey()]}
          mode="inline"
          items={items}
          style={{
            backgroundColor: "transparent",
          }}
        />
      </Sider>
      <Layout className="site-layout">
        <Header style={{ padding: 0 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: 64,
              backgroundColor: theme.header,
              padding: "0 24px",
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
          >
            <React.Fragment>
              {!isMobile ? (
                <React.Fragment>
                  <h4 style={{ margin: 0 }}>{t("Name_APP")}</h4>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginLeft: "8px" }}>{avatarMenu}</div>
                    <div style={{ marginLeft: "8px" }}>{user.name}</div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginLeft: "8px" }}>{avatarMenu}</div>
                    <div style={{ marginLeft: "8px" }}>{user.name}</div>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </div>
        </Header>

        <Content style={{ margin: "0 16px" }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>{breadcrumb}</Breadcrumb.Item>
          </Breadcrumb>
          <Card
            style={{
              position: "relative",
              height: "calc(100% - 32px)",
              width: "100%",
            }}
            bodyStyle={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
            }}
          >
            {gotoback && (
              <Link to={gotoback}>
                <Button
                  type="dashed"
                  icon={<ArrowLeftOutlined />} // Adicione o ícone aqui
                  shape="round"
                  style={{
                    width: "100%",
                    maxWidth: "120px",
                    position: "absolute",
                    top: 8,
                    right: 8,
                    zIndex: 1,
                  }}
                >
                  {t("Gotoback")}
                </Button>
              </Link>
            )}
            {children}
          </Card>
        </Content>
        <Footer style={{ textAlign: "center" }}>BovControl ©2023</Footer>
      </Layout>
    </Layout>
  );
}

export default Base;
