import { createGlobalStyle } from "styled-components";
import backgroundImage from "../src/assets/backgroundImage.webp";

const GlobalStyles = createGlobalStyle`
  body {
    background-color: #fffaf0;
  }

  body,
    html {
    height: 100%;
    margin: 0;
    padding: 0;
    background-image: url(${backgroundImage});
    background-size: contain;
    background-position: right center;
    background-repeat: no-repeat;
    }

    /* Edu, esse aqui é o stylo do botão de confirmar, eu peguei do Sabiá Admin Antigo, */
    .confirm_button {
    background-image: linear-gradient(80deg, rgb(79, 175, 73) 1%, rgb(31, 150, 82));
    border-color: rgb(31, 150, 82);
  }

  /* Edu esse stylo são para os inputs, para tirar as bordas */
  .input {
    border: none;
    border-bottom: 1px solid #d9d9d9;
  }

  // Aqui é para ser usado no Link do React Route Dom
  .link_react-router-dom {
    color: #1f9652;
  }

  .slide {
    color: #47342eb8;
  }

  .custom-card {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fffaf0;
  }
  
  .card-content {
    text-align: center;
  }
  
  .centered-content {
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--checkbox-border-color);
    border-color: var(--checkbox-border-color);
  }

`;

export default GlobalStyles;
